import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://ba3d301819cc4156a04140fafa457db3@o4504179120472064.ingest.sentry.io/4504348686286848",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  release: "f532fd639437fa0cee0180f26c99eda9d12a5fed",
  environment: "production"
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);